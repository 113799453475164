import {useState, useEffect, useMemo} from 'react';
import {Box} from './Box';
import './styles/NetworkStats.css';


export const NetworkStats = (props) => {
    // const [updateRate, setUpdateRate] = useState(1);
    const updateRate = 1;
    const [iface, setIface] = useState("");
    const [interfaces, setInterfaces] = useState([]);
    const [stats, setStats] = useState({});
    const [lastUpdate, setLastUpdate] = useState("Never");

    const server = props.server
    const token = props.token;

    useMemo( () => {
        const url = `${server}/network/interfaces`;
        fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }
        )
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            setInterfaces(json);
            setIface(json[0]);
        })
        .catch(error => {
            console.error(error);
        });
    }, [server, token]);

    useEffect( () => {
        const getStats = () => {
            if (iface === "")
                return;
            const url = `${server}/network/interfaces/${iface}/statistics`;
            fetch(
                url,
                {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            )
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                setStats(json)
                const d = new Date()
                setLastUpdate(d.toUTCString());
            })
            .catch(error => {
                console.error(error);
            });
        };

        let interval = null;
        if (updateRate > 0)
        {
            interval = setInterval(getStats, updateRate * 1000);
        }
        return () => clearInterval(interval);
    }, [updateRate, server, iface, token]);

    const interfaceChanged = (event) => setIface(event.target.value);

    return (
        <Box {...props} width="300" height="250" title="Network Statistics">
            <div className="netstat">
                <div id="netstat-header">
                    <label>
                        Interface:
                        <select
                            onChange={interfaceChanged}
                            name="netstat-type"
                            id="netstat-type"
                            defaultValue={iface}
                        >
                            {
                                interfaces.map(
                                    element => <option
                                                key={element}
                                                value={element}
                                            >
                                                {element}
                                            </option>
                                )
                            }
                        </select>
                    </label>
                </div>
                <div id="netstat-body">
                <table>
                    {
                        Object.keys(stats).map(
                            key =>
                                <tr>
                                <th style={{"textAlign": "left"}}>{key}</th>
                                <td>{stats[key]}</td>
                                </tr>
                        )
                    }
                </table>
                </div>
                <div id="netstat-footer">
                    <label>Updated: {lastUpdate}</label>
                </div>
            </div>
        </Box>
    );
};
