import React from 'react';
import {LoginForm} from './Login-Form';
import {LogsViewer} from './LogsViewer';
import {ChangeVPNPort} from './ChangeVPNPort';
import {NetworkStats} from './NetworkStats';
import {VPNStats} from './VPNStats';
import './styles/App.css';


const SERVER = 'https://api.misrael.de'


async function getUsername(token) {
    const response = await fetch(
        SERVER.concat('/users/me'),
        {
            method: 'GET',
            headers: {
                accept: 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }
    )
    .then(response => {
        if (response.status !== 200) {
            throw Error("Not logged in");
        }
        return response.json();
    })
    return response;
}

export const App = () => {
    const [boxes, setBoxes] = React.useState([]);
    const [freeKeys] = React.useState([]);
    const [user, setUser] = React.useState(null)
    const [token, setToken] = React.useState(null)
    const [loaded, setLoaded] = React.useState(false)

    if(!loaded)
    {
        const readCookie = async function() {
            const cookie = document.cookie;
            try
            {
                const json_cookie = JSON.parse(cookie);
                setToken(json_cookie.access_token)
            }
            catch
            {
                console.debug("Failed to read cookie");
            }
            setLoaded(true);
        }
        readCookie();
    };

    React.useMemo( () => {
        if(!token)
        {
            return;
        }

        getUsername(token)
        .then(current_user => setUser(current_user))
    }, [token]);

    const BoxTypes = {
        "Logs Viewer": LogsViewer,
        "Change VPN Port": ChangeVPNPort,
        "Network Statistics": NetworkStats,
        "VPN Statistics": VPNStats,
    };

    const closeHook = ({key}, boxes) => {
        const new_list = boxes.filter(box => box.props.key !== key);
        setBoxes(new_list);
        freeKeys.push(key)
    };

    const createBox = (e) => {
        var key = boxes.length;
        if (freeKeys.length > 0) {
            key = freeKeys.pop()
        };
        const newBox = {
            name: e.target.value,
            props: {
                server: SERVER,
                key: key,
                token: token,
                hooks: {
                    close: {
                        fcn: closeHook,
                        props: {
                            key: key,
                        },
                    },
                },
            },
        };
        setBoxes([...boxes.slice(0, boxes.length), newBox]);

        e.target.value = ""
    };

    const logout = () => {
        const cookie = document.cookie;
        let json_cookie = JSON.parse(cookie);
        json_cookie['access_token'] = ""
        document.cookie = JSON.stringify(json_cookie);
        setToken(null);
        return false;
    };

    if(!loaded)
    {
        return (
            <div className="App">
                <div id="App-loading">
                    <label>Loading, please wait...</label>
                </div>
            </div>
        );
    }

    if(!token)
    {
        return (
            <div className="App">
                <div id="App-loading">
                    <LoginForm server={SERVER} tokenSetter={setToken} />
                </div>
            </div>
        );
    }

    if (!user)
    {
        return (
            <div className="App">
                <div id="App-loading">
                    <label >Signing in...</label>
                </div>
            </div>
        );
    }

    return (
        <div className="App">
            <div id="header">
                <div id="header-actions">
                    <select onChange={createBox} name="boxes" id="boxes">
                        <option value="">[Create Box]</option>
                        {
                            Object.keys(BoxTypes).map(
                                key => <option key={key} value={key}>{key}</option>
                            )
                        }
                    </select>
                </div>
                <div id="header-title">
                Michael's access point
                </div>
                <div id="header-user">
                    <label>Welcome {user.fullname}</label>
                    <br />
                    <button
                        id="btn_logout"
                        onClick={logout}
                    >
                        Logout
                    </button>

                </div>
            </div>
            <div id="body">
                {
                    boxes.map(boxObject => React.createElement(
                        BoxTypes[boxObject.name],
                        {
                            ...boxObject.props,
                            boxes: boxes,
                        }
                    ))
                }
            </div>
        </div>
    );
};
