import {useState, useEffect, useMemo} from 'react';
import {Box} from './Box';
import './styles/VPNStats.css';


export const VPNStats = (props) => {
    // const [updateRate, setUpdateRate] = useState(1);
    const updateRate = 1;
    const [iface, setIface] = useState("");
    const [interfaces, setInterfaces] = useState([]);
    const [stats, setStats] = useState({});
    const [headers, setHeaders] = useState([]);
    const [lastUpdate, setLastUpdate] = useState("Never");

    const server = props.server
    const token = props.token;

    useMemo( () => {
        const url = `${server}/network/vpn/interfaces`;
        fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }
        )
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            setInterfaces(json);
            setIface(json[0]);
        })
        .catch(error => {
            console.error(error);
        });
    }, [server, token]);

    useEffect( () => {
        const getVPNStats = () => {
            if (iface === "")
                return;
            const url = `${server}/network/vpn/interfaces/${iface}/statistics`;
            fetch(
                url,
                {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            )
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(json => {
                setStats(prevStats => {
                    return {
                        ...prevStats,
                        ...json,
                    };
                });
                setHeaders(["Peer", ...Object.keys(json[Object.keys(json)[0]])]);

                const d = new Date();
                setLastUpdate(d.toUTCString());
            })
            .catch(error => {
                console.error(error);
            });
        };

        let interval = null;
        if (updateRate > 0)
        {
            interval = setInterval(getVPNStats, updateRate * 1000);
        }
        return () => clearInterval(interval);
    }, [updateRate, server, iface, token]);

    const interfaceChanged = (event) => setIface(event.target.value);

    return (
        <Box {...props} width="850" height="220" title="VPN Statistics">
            <div className="vpnstats">
                <div id="vpnstats-header">
                    <label>
                        Interface:
                        <select
                            onChange={interfaceChanged}
                            name="vpnstats-type"
                            id="vpnstats-type"
                            defaultValue={iface}
                        >
                            {
                                interfaces.map(
                                    element => <option
                                                key={element}
                                                value={element}
                                            >
                                                {element}
                                            </option>
                                )
                            }
                        </select>
                    </label>
                </div>
                <div id="vpnstats-body">
                <table>
                    <tbody>
                        <tr>
                            {
                                headers.map(
                                    key => <th style={{"textAlign": "left"}}>{key}</th>
                                )
                            }
                        </tr>
                    </tbody>
                    {
                        Object.keys(stats).map((key) =>
                            {
                            return (
                                <tr>
                                <th style={{"textAlign": "left"}}>{key}</th>
                                {
                                    Object.keys(stats[key]).map(
                                        key2 => <td>{stats[key][key2]}</td>
                                    )
                                }
                                </tr>
                            )
                            }
                        )
                    }
                </table>
                </div>
                <div id="vpnstats-footer">
                    <label>Updated: {lastUpdate}</label>
                </div>
            </div>
        </Box>
    );
};
